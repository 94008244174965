import { Toaster } from '@/components/atomic/molecules';

const ToastContainerOrganism = ({ setShowToast, showToast }) => {
  const { message, position, show, toastFlag } = showToast;
  return (
    show && (
      <div className='flex justify-center items-end mx-auto gap-4'>
        <Toaster
          {...{
            iconSrc: toastFlag ? 'checked-green.svg' : 'failed.svg',
            message,
            position,
            setShowToast,
            showIcon: true
          }}
        />
      </div>
    )
  );
};

export default ToastContainerOrganism;
