import { BID_STATUS, TENDER_STATUS } from 'config/bid';
import { calculateTotalTimeDifferenceInSeconds } from 'lib/dateAndTime';
import { parseFormatPriceNumberValueFromAPI } from 'lib/numberStringUtils';
import * as HttpService from 'services/http.service';
import {
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue
} from 'services/localStorage.service';
import {
  GET_BID_DETAILS_BY_PARTNER_AND_REFERENCE_ID_API_URL,
  POST_CALLBACK_REQUEST_FOR_BID_API_URL,
  SUBMIT_BID_API_URL,
  UPDATE_BID_ITEM_API_URL
} from 'services/url.service';

const postCallBackRequestForBidAPI = async ({ bidId, messageFromPartner }) =>
  HttpService.postWithAuth(POST_CALLBACK_REQUEST_FOR_BID_API_URL(bidId), {
    messageFromPartner
  });

const getBidDetailsByPartnerAndReferenceIdAPI = ({ partnerId, referenceId }) =>
  HttpService.getWithAuth(
    GET_BID_DETAILS_BY_PARTNER_AND_REFERENCE_ID_API_URL({
      partnerId,
      referenceId
    })
  );

export const updateBidItemAPI = ({ bidId, bidItemId, data }) =>
  HttpService.putWithAuth(UPDATE_BID_ITEM_API_URL(bidId, bidItemId), data);

export const submitBidAPI = ({ bidId, data }) =>
  HttpService.postWithAuth(SUBMIT_BID_API_URL(bidId), data);

const isUserEventExpired = ({
  eventStartTime,
  status,
  tenderStatus,
  userEventDate
}) => {
  const statusObj = {
    ACTIVE:
      calculateTotalTimeDifferenceInSeconds(userEventDate, eventStartTime) <= 0,
    EXPIRED: true
  };
  return tenderStatus === TENDER_STATUS.ACTIVE && statusObj[status];
};

const parseGetBidDetailsAPIResponse = (bidDetailsRaw = {}) => {
  const {
    cartEventInfo: { eventStartTime, userEventDate },
    status,
    tenderStatus
  } = bidDetailsRaw;
  const isEventExpired = isUserEventExpired({
    eventStartTime,
    status,
    tenderStatus,
    userEventDate
  });
  return {
    ...bidDetailsRaw,
    isEventExpired,
    status: isEventExpired ? BID_STATUS.EXPIRED.value : status
  };
};

const getBidDetails = async ({
  partnerId,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode
}) => {
  const {
    entity: bidDetailsRaw,
    message,
    status
  } = await getBidDetailsByPartnerAndReferenceIdAPI({ partnerId, referenceId });

  const bidDetails =
    status && bidDetailsRaw?.id
      ? parseGetBidDetailsAPIResponse(bidDetailsRaw)
      : false;

  if (bidDetails) {
    setDeliveryMode(bidDetails.summary.deliveryMode);
    setDeliveryChargeOffered(
      parseFormatPriceNumberValueFromAPI(bidDetails.summary.deliveryCharge)
    );
    setBidDetails(bidDetails);
  }

  return { message, status };
};

export const postCallBackRequestForBid = async ({
  bidId,
  messageFromPartner,
  setShowRequestCallbackModal,
  setShowToast
}) => {
  const { apiStatus, message } = await postCallBackRequestForBidAPI({
    bidId,
    messageFromPartner
  });
  if (apiStatus) {
    setShowRequestCallbackModal(false);
  }
  const apiMessage = message ?? message[0];
  const toastMessage = apiStatus
    ? 'Request placed successfully!'
    : `Error! Failed to submit. Please try again. (${apiMessage})`;
  setShowToast({
    message: toastMessage,
    show: true,
    toastFlag: apiStatus
  });
};

export const updateBidItem = async ({
  bidId,
  bidItemId,
  data,
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setOpenBidItemDetail,
  setShowToast
}) => {
  setLoading(true);
  try {
    const { message, status } = await updateBidItemAPI({
      bidId,
      bidItemId,
      data
    });
    if (status) {
      await getBidDetails({
        partnerId: partnerUser.partnerOrgId,
        referenceId,
        setBidDetails,
        setDeliveryChargeOffered,
        setDeliveryMode
      });
      setOpenBidItemDetail(null);
    }
    setShowToast({ message, show: true, toastFlag: status });
  } catch (error) {
    setShowToast({
      message: error.message || 'Failed to update',
      show: true,
      toastFlag: false
    });
  } finally {
    setLoading(false);
  }
};

export const fetchBidDetail = async ({
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, status } = await getBidDetails({
    partnerId: partnerUser.partnerOrgId,
    referenceId,
    setShowToast,
    setBidDetails,
    setDeliveryChargeOffered,
    setDeliveryMode
  });
  !status &&
    setShowToast({
      message: message ?? [message],
      show: true,
      toastFlag: status
    });
  setLoading(false);
};

export const submitBid = async ({
  bidId,
  data,
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  try {
    const { apiStatus, message } = await submitBidAPI({
      bidId,
      data
    });
    if (apiStatus) {
      await getBidDetails({
        partnerId: partnerUser.partnerOrgId,
        referenceId,
        setBidDetails,
        setDeliveryChargeOffered,
        setDeliveryMode
      });
    }
    setLocalStorageValue({
      key: LOCAL_STORAGE_KEYS.IS_BID_LIST_INDEX_UPDATED,
      value: true
    });
    setShowToast({ message, show: true, toastFlag: apiStatus });
  } catch (error) {
    setShowToast({
      message: error.message || 'Failed to update',
      show: true,
      toastFlag: false
    });
  } finally {
    setLoading(false);
  }
};
