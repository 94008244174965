import { PARTNER_PORTAL_URL } from 'services/connections.service';
import * as HttpService from 'services/http.service';
import { PAGE_NAME, getPageURL } from 'services/partnerPortal.service';
import { GET_PARTNER_SUMMARY_STATS_API_URL } from 'services/url.service';

const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';
const whatsappNumber = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER;

export const getPartnerSummaryStatsAPI = () =>
  HttpService.getWithAuth(GET_PARTNER_SUMMARY_STATS_API_URL());

export const PARTNER_TIER = {
  BRONZE: {
    label: 'Bronze',
    icon: 'bronze-icon.svg',
    value: 'BRONZE',
    starIcon: 'bronze-star.svg',
    valueStyle: 'text-royal-orange'
  },
  GOLD: {
    label: 'Gold',
    icon: 'gold.svg',
    value: 'GOLD',
    starIcon: 'gold-star.svg',
    valueStyle: 'text-misty-moss'
  },
  SILVER: {
    label: 'Silver',
    icon: 'silver.svg',
    value: 'SILVER',
    starIcon: 'silver-star.svg',
    valueStyle: 'text-light-silver'
  }
};

const getPartnerTier = (orderServedTillDateValue) => {
  const isSilver = (value) => value > 75000 && value < 250000;
  const isGold = (value) => value >= 250000;

  switch (true) {
    case isGold(orderServedTillDateValue):
      return PARTNER_TIER.GOLD.value;

    case isSilver(orderServedTillDateValue):
      return PARTNER_TIER.SILVER.value;

    default:
      return PARTNER_TIER.BRONZE.value;
  }
};

export const parsePartnerSummaryStats = ({ entity }) => {
  const {
    newQuoteCount,
    ordersServedTillDateCount,
    ordersServedTillDateValue,
    ordersToServeTodayTomorrowCount,
    responseRate,
    totalInquiriesReceived,
    upcomingOrderCount
  } = entity;

  const menuItemList = [
    {
      className: 'text-bluish-cyan',
      count: `${newQuoteCount} New Quotes`,
      href: getPageURL({ pageName: PAGE_NAME.BID_LIST.label }),
      icon: 'quotes.svg',
      label: 'Quotes',
      shortDescription:
        'List of quote request for product availability and pricing.'
    },
    {
      className: 'submitted-badge',
      count: `${upcomingOrderCount} Upcoming Orders`,
      href: getPageURL({ pageName: PAGE_NAME.ORDER_LIST.label }),
      icon: 'partner-order-icon.svg',
      label: 'Orders',
      shortDescription: 'List of all confirmed orders - upcoming and past'
    },
    {
      className: 'in-progress-badge',
      count: `${ordersToServeTodayTomorrowCount} Deliveries and Pickups`,
      href: getPageURL({
        pageName: PAGE_NAME.ORDER_DELIVERY_AND_PICKUPS.label
      }),
      icon: 'deliver-and-pickup.svg',
      label: 'Deliveries & Pickups',
      shortDescription:
        'List of all orders to be delivered or picked-up today or tomorrow'
    }
  ];
  const statsArray = [
    {
      count: totalInquiriesReceived,
      countLabel: 'Inquiries',
      heading: 'Quote Requests',
      iconUrl: 'quote-check-icon.svg',
      value: `${responseRate ?? 0}%`,
      valueLabel: 'Submission Rate'
    },
    {
      count: ordersServedTillDateCount,
      countLabel: 'Orders',
      heading: 'Business till date',
      iconUrl: 'stats-icon.svg',
      value: ordersServedTillDateValue.toLocaleString('en-IN', {
        maximumFractionDigits: 0
      }),
      valueLabel: 'Order Value'
    }
  ];
  return {
    menuItemList,
    partnerTier: getPartnerTier(ordersServedTillDateValue),
    statsArray
  };
};

export const fetchPartnerSummaryStats = async ({
  setLoading,
  setPartnerSummaryStats,
  setShowToast
}) => {
  try {
    setLoading(true);
    const { entity, message, status } = await getPartnerSummaryStatsAPI();
    if (status) {
      setPartnerSummaryStats(parsePartnerSummaryStats({ entity }));
    } else {
      setShowToast({ message, show: true, toastFlag: status });
    }
  } catch (error) {
    setShowToast({
      message: error.message || 'Failed to get Partner stats',
      show: true,
      toastFlag: false
    });
  } finally {
    setLoading(false);
  }
};

export const getInitiateWhatsappChatLink = ({ isMobile }) => {
  const showFullURL = true;
  const { pathname, searchParams } = new URL(window.location.href);
  const pageURLWithoutQueryParam = `${PARTNER_PORTAL_URL}${pathname}`;

  const searchString = searchParams.toString();
  const encodedQueryString =
    searchString.length > 0 ? `, ${encodeURIComponent(searchString)}` : '';

  const messagePrefix = isProduction
    ? ''
    : `Please Ignore this - this message is from the non production portal. \n\n`;

  const whatsAppBaseURL = isMobile
    ? 'https://api.whatsapp.com/send'
    : 'https://web.whatsapp.com/send';

  const fullURL = showFullURL
    ? `\n\n${pageURLWithoutQueryParam}${encodedQueryString}\n\n`
    : '';

  const formattedMessage = `${messagePrefix}Hi Hafla! I have a query on - ${pageURLWithoutQueryParam}\n\nPlease get in touch.${fullURL}`;

  return encodeURI(
    `${whatsAppBaseURL}?phone=${whatsappNumber}&text=${formattedMessage}`
  );
};
